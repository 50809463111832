import React, { useState } from 'react';
import './WBSPage.css';
import MLDLucky from './MLDUser';
import MLDNotification from './MLDNotification';
const Tab = ({ activeTab, setActiveTab, label }) => {
    const handleClick = () => {
        setActiveTab(label);
    };

    return (
        <div
            className={`tab ${activeTab === label ? 'active' : ''}`}
            onClick={handleClick}
        >
            {label}
        </div>
    );
};

const UserTabContent = () => {
    return (
        <div>
            <MLDLucky />
        </div>
    );
};

const NotificationLogTabContent = () => {
    return (
        <div>
            <MLDNotification />
        </div>
    );
};

const TabComponent = () => {
    const [activeTab, setActiveTab] = useState('User');

    return (
        <div>
            <div className="tab-container">
                <Tab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    label="User"
                />
                <Tab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    label="Notification Log"
                />
            </div>

            <div className="tab-content">
                {activeTab === 'User' && <UserTabContent />}
                {activeTab === 'Notification Log' && <NotificationLogTabContent />}
            </div>
        </div>
    );
};

export default TabComponent;
