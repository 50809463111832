import React from 'react'
import logo from "../../Asset/Images/Navbar/logo.jpg"
import logoname from "../../Asset/Images/Navbar/logoname.jpg"
import mld from "../../Asset/Images/work/mld.jpg";
// import MLDmockup1 from "../../Asset/Images/product/mldmockup/MLDmockup1.jpg"
// import MLDmockup2 from "../../Asset/Images/product/mldmockup/MLDmockup2.jpg"
// import MLDmockup3 from "../../Asset/Images/product/mldmockup/MLDmockup3.jpg"
import MLDmockup1 from "../../Asset/Images/product/NewMLDmockup/NewMLDmockup1.svg"
import MLDmockup2 from "../../Asset/Images/product/NewMLDmockup/NewMLDmockup2.svg"
import MLDmockup3 from "../../Asset/Images/product/NewMLDmockup/NewMLDmockup3.svg"
import MLDmockup4 from "../../Asset/Images/product/NewMLDmockup/NewMLDmockup4.svg"
import twitter from "../../Asset/Images/product/twitter.jpg";
import Instagram from "../../Asset/Images/contact/Instagram.jpg";
import linkedin from "../../Asset/Images/product/linkedin.jpg";
import "../Product/Product.css"
import googleplay from "../../Asset/Images/product/googleplay.jpg"
import fb from "../../Asset/Images/product/fb.jpg";
import { Link } from "react-router-dom";
import Navbar from '../Navbar/Navbar';
const Product = () => {
    return (
        <div>
            <Navbar />
            <div className="container-fluid mb-6">
                <div className="row">
                    <div className="col-10 mx-auto">
                        <div className="row">
                            <div className="col-md-10 col-10 mx-auto">

                                <div className="product">
                                    <div className="work_1">
                                        <h1 className='product_name'>My Lucky Days</h1>
                                    </div>
                                    <div className="product_left">
                                        <img src={mld} className="img-fluid" alt="1" />
                                    </div>
                                    <div className='product_align'>
                                        <div className="product_middle">
                                            <h2>About the Product</h2>
                                        </div>
                                        <div className="product_desc">
                                            <p>My Lucky days is world's first quantitative astrology app. We are just getting started on this journey where users can shape their own personal algorithm that predicts best results for themselves.</p>
                                        </div>
                                        <div className="product_mock">
                                            <h2>Mockups</h2>
                                        </div>
                                        <div className="mockup">

                                            <img className="img-fluid mockupimg" src={MLDmockup1} alt="" />



                                            <img className="img-fluid mockupimg" src={MLDmockup2} alt="" />


                                            <img className="img-fluid mockupimg" src={MLDmockup3} alt="" />


                                            <img className="img-fluid mockupimg" src={MLDmockup4} alt="" />

                                            {/* <div className=" mockup order-1 order-lg-1"> */}
                                            {/* <img className="order-1" src={image3} alt="" /> */}
                                            {/* </div> */}
                                            {/* <div className="mockup order-2 order-lg-1"> */}
                                            {/* <img className="order-2" src={image4} alt="" /> */}
                                            {/* </div> */}
                                            {/* <div className="mockup order-3 order-lg-1"> */}
                                            {/* <img className="order-3" src={image5} alt="" /> */}
                                            {/* </div> */}
                                        </div>
                                        <div className="product_download">
                                            <h2>Download it Now</h2>
                                        </div>
                                        <div className="app_download">
                                            <div className="googleplay" >
                                                <Link to={'//play.google.com/store/apps/details?id=tech.kushmanda.luckydays'} target="_blank">
                                                    <img className="download_image" src={googleplay} alt="goo" />
                                                </Link>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="product_reach">
                                        <h3>Reach Us At</h3></div>
                                    <div className="social">
                                        <div className="s">
                                            <Link to={'//www.twitter.com/_MyLuckyDays_'} target="_blank">
                                                <img className="download_image" src={twitter} alt="goo" />
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={'//www.instagram.com/myluckydays_app/'} target="_blank">
                                                <img className="download_image" src={Instagram} alt="goo" />
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={'//www.facebook.com/profile.php?id=100091541174673'} target="_blank">
                                                <img className="download_image" src={fb} alt="goo" />
                                            </Link>
                                        </div>
                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aboutproduct_left">
                <img className="img-fluid" src={logo} alt="logo" />
                <div className="aboutproduct_logo">
                    <img className="img-fluid" src={logoname} alt="logoname" />
                </div>
            </div>
        </div>
    )
}
export default Product;