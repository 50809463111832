import React, { useEffect, useState } from 'react';
import './WBSPage.css';

const MLDNotification = () => {
    const [userData, setUserData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchUserData();
    }, [currentPage]);

    const fetchUserData = async () => {
        try {
            const response = await fetch(
                `https://dev.myluckydays.app/api/V2.0/Notification/get_notification_log?pageNumber=${currentPage - 1}&pageSize=10&sortMode=Asc`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Environment': 'MLD DEV'
                    }
                }
            );
            const data = await response.json();
            setUserData(data);
            console.log('User Data:', data); // Log the received data in the console
        } catch (error) {
            console.log('Error fetching user data:', error);
        }
    };

    const handleNextPage = () => {
        if (userData?.data && userData.data.length === 10) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <div>
            <h1>MLD Notification</h1>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        {/* <th>FCM Token</th> */}
                        <th>Success/Failure</th>
                        <th>For All/Individual</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(userData?.data) && userData.data.length > 0 ? (
                        userData.data.map((user) => {
                            console.log('User Data:', user); // Log user data
                            console.log('Notification Type ID:', user.notification_type_id); // Log notification_type_id
                            // Convert the notification_type_id to a number
                            const successResponse = parseInt(user.success, 10);
                            const notificationTypeId = parseInt(user.notification_type_id, 10);
                            return (
                                <tr key={user.user_id}>
                                    <td>{user.your_name}</td>
                                    <td>{user.email}</td>
                                    {/* <td>{user.success === 0 ? 'Failed' : 'Success'}</td>
                                    <td>{user.notification_type_id === 1 ? 'For All' : 'For One'}</td> */}
                                    <td>{successResponse === '0' ? 'Failed' : 'Success'}</td>
                                    <td>{notificationTypeId === 1 ? 'For All' : 'Individual'}</td>
                                    {/* <td>{user.notification_type_id}</td> */}
                                    <td>{user.last_updated_date}</td>
                                </tr>
                            );
                        }
                        )
                    ) : (
                        <tr>
                            <td colSpan="4">No user data available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage}</span>
                <button onClick={handleNextPage} disabled={!userData?.data || userData.data.length < 10}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default MLDNotification;
