import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <h1>Welcome to the Notification Page</h1>
            <div className="button-container">
                <Link to="/wbs" className="landing-button">WBS</Link>
                <Link to="/mldnotify" className="landing-button">MLD</Link>
                <Link to="/aries" className="landing-button">Aries</Link>
            </div>
            {/* <div className="button-container">
                <button className="landing-button">WBS</button>
                <button className="landing-button">MLD</button>
                <button className="landing-button">Aries</button>
            </div> */}
        </div>
    );
}

export default LandingPage;
