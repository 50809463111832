import React, { useEffect, useState } from 'react';
import './WBSPage.css';
const WBSPage = () => {
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        fetchUserData();
    }, []);
    const fetchUserData = async () => {
        try {
            const response = await fetch(
                'https://dev.myluckydays.app/api/V2.0/Notification/get_user_by_birthday',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Environment': 'MLD DEV V2.0'
                    }
                }
            );
            const data = await response.json();
            setUserData(data);
            console.log('User Data:', data); // Log the received data in the console
        } catch (error) {
            console.log('Error fetching user data:', error);
        }
    };

    // const fetchUserData = async () => {
    //     try {
    //         const response = await fetch(
    //             'https://dev.myluckydays.app/api/V2.0/Notification/get_user_by_birthday',
    //             {
    //                 method: 'GET',
    //                 headers: {
    //                     'X-Environment': 'MLD DEV V2.0'
    //                 }
    //             }
    //         );
    //         const data = await response.json();
    //         setUserData(data);
    //     } catch (error) {
    //         console.log('Error fetching user data:', error);
    //     }
    // };

    const handleSendNotification = (userId) => {
        // Logic to send notification for the given user
        console.log('Send notification for user with ID:', userId);
    };

    return (
        <></>
        // <div>
        //     <h1>WBS Page</h1>
        //     <table>
        //         <thead>
        //             <tr>
        //                 <th>Name</th>
        //                 <th>Email</th>
        //                 <th>FCM Token</th>
        //                 <th>Notification</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {Array.isArray(userData?.data) && userData.data.length > 0 ? (
        //                 userData.data.map((user) => (
        //                     <tr key={user.user_id}>
        //                         <td>{user.your_name}</td>
        //                         <td>{user.email}</td>
        //                         <td>{user.fcm_token}</td>
        //                         <td>
        //                             <button
        //                                 className="send-button"
        //                                 onClick={() => handleSendNotification(user.id)}
        //                             >
        //                                 Send
        //                             </button>
        //                         </td>
        //                     </tr>
        //                 ))
        //             ) : (
        //                 <tr>
        //                     <td colSpan="4">No user data available.</td>
        //                 </tr>
        //             )}
        //         </tbody>

        //     </table>
        // </div>
    );
};

export default WBSPage;
