import React from 'react';
import { Link } from 'react-router-dom';
// import './LandingPage.css';

const MLDLanding = () => {
    return (
        <div className="landing-page">
            <h1>Welcome to the MLD Notification Page</h1>
            <div className="button-container">
                <Link to="/mldbirthday" className="landing-button">User Birthday List</Link>
                <Link to="/mldlucky" className="landing-button">User Lucky Day List</Link>

            </div>
            {/* <div className="button-container">
                <button className="landing-button">WBS</button>
                <button className="landing-button">MLD</button>
                <button className="landing-button">Aries</button>
            </div> */}
        </div>
    );
}

export default MLDLanding;