import React from 'react'
import logo from "../../Asset/Images/Navbar/logo.jpg"
import logoname from "../../Asset/Images/Navbar/logoname.jpg"
// import wbsMockup1 from "../../Asset/Images/product/wbsmockup/wbsMockup1.png"
// import wbsMockup2 from "../../Asset/Images/product/wbsmockup/wbsMockup2.png"
// import wbsMockup3 from "../../Asset/Images/product/wbsmockup/wbsMockup3.png"
// import wbsMockup4 from "../../Asset/Images/product/wbsmockup/wbsMockup4.png"
import wbsMockup1 from "../../Asset/Images/product/wbsmockup/mockwbs1.svg"
import wbsMockup2 from "../../Asset/Images/product/wbsmockup/mockwbs2.svg"
import wbsMockup3 from "../../Asset/Images/product/wbsmockup/mockwbs3.svg"
import wbsMockup4 from "../../Asset/Images/product/wbsmockup/mockwbs4.svg"


// import wbsMockup1 from "../../Asset/Images/product/Newwbsmockup/wbsmock1.svg"
// import wbsMockup2 from "../../Asset/Images/product/Newwbsmockup/wbsmock2.svg"
// import wbsMockup3 from "../../Asset/Images/product/Newwbsmockup/wbsmock3.svg"
// import wbsMockup4 from "../../Asset/Images/product/Newwbsmockup/wbsmock4.svg"

import twitter from "../../Asset/Images/product/twitter.jpg";
import linkedin from "../../Asset/Images/product/linkedin.jpg";
import Instagram from "../../Asset/Images/contact/Instagram.jpg";
import "../Product/Product.css"
import googleplay from "../../Asset/Images/product/googleplay.jpg"
import appstore from "../../Asset/Images/product/appstore.jpg"
import fb from "../../Asset/Images/product/fb.jpg";
import wbs from "../../Asset/Images/product/wbsmockup/wbsapp.webp";
import { Link } from "react-router-dom";
import Navbar from '../Navbar/Navbar'
const Product = () => {
    return (
        <div>
            <Navbar />
            <div className="container-fluid mb-6">
                <div className="row">
                    <div className="col-10 mx-auto">
                        <div className="row">
                            <div className="col-md-10 col-10 mx-auto">

                                <div className="product">
                                    <div className="product_1">
                                        <h1 className='product_name'>Wiki Bedtime Stories</h1>
                                    </div>
                                    <div className="product_left">
                                        <img src={wbs} className="img-fluid" alt="1" />
                                    </div>
                                    <div className='product_align'>
                                        <div className="product_middle">
                                            <h2>About the Product</h2>
                                        </div>
                                        <div className="product_desc">
                                            <p>Wiki bedtime stories started with a simple concept of providing new story to parents every day to tell their kids. Audio part is evolving now as well. Stories should come from community of story lovers. Its world's first app with intent to preserve culture of storytelling via community.</p>
                                        </div>
                                        <div className="product_mock">
                                            <h2>Mockups</h2>
                                        </div>
                                        <div className="mockup">
                                            <img className="img-fluid mockupimg" src={wbsMockup1} alt="" />
                                            <img className="img-fluid mockupimg" src={wbsMockup2} alt="" />
                                            <img className="img-fluid mockupimg" src={wbsMockup3} alt="" />
                                            <img className="img-fluid mockupimg" src={wbsMockup4} alt="" />
                                        </div>
                                        <div className="product_download">
                                            <h2>Download it Now</h2>
                                        </div>
                                        <div className="app_download">
                                            <div className="googleplay" >
                                                <Link to="//play.google.com/store/apps/details?id=tech.kushmanda.wikibedtimestories" target="_blank">
                                                    <img className="download_image" src={googleplay} alt="goo" />
                                                </Link>

                                            </div>
                                            <div className="appstore">
                                                <Link to="//apps.apple.com/us/app/wiki-bedtime-stories/id1483269251" target="_blank">
                                                    <img className="download_image" src={appstore} alt="goo" />
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='ppp1'>
                                        <div className="product_reach">
                                            <h3>Reach Us At</h3>
                                        </div>

                                        <div className="social">
                                            <div className="s">
                                                <Link to={'//twitter.com/wikibedtime'} target="_blank">
                                                    <img className="download_image" src={twitter} alt="goo" />
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to={'//www.instagram.com/wikibedtimestories/'} target="_blank">
                                                    <img className="download_image" src={Instagram} alt="goo" />
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to={'//www.facebook.com/profile.php?id=100076326081861'} target="_blank">
                                                    <img className="download_image" src={fb} alt="goo" />
                                                </Link>
                                            </div>

                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aboutproduct_left">
                <img className="img-fluid" src={logo} alt="logo" />
                <div className="aboutproduct_logo">
                    <img className="img-fluid" src={logoname} alt="logoname" />
                </div>
            </div>

        </div>
    )
}
export default Product;